import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Section = styled.section`
  ${tw`
    py-20
    md:py-32
    relative
    w-full
  `}
  &.bottom {
    ${tw`
      pb-0
    `}
  }
  &.top {
    ${tw`
      pt-0
    `}
  }
  &.disabled-pb {
    ${tw`
      pt-20
      md:pt-32
      overflow-hidden
      relative
      w-full
    `}
  }
  &.disabled-pt {
    ${tw`
      pb-20
      md:pb-32
      overflow-hidden
      relative
      w-full
    `}
  }
  &.dark-blue {
    ${tw`
      bg-blue-800
    `}
  }
  &.light-blue {
    background-color: #f5f8fc;
  }
  &.light-teal {
    ${tw`
      bg-teal-100
    `}
  }
  &.light-orange {
    ${tw`
      bg-orange-100
    `}
  }
  &.light-green {
    ${tw`
      bg-green-100
    `}
  }
  &.developer {
    ${tw`
      -mt-36
      md:-mt-64
    `}
  }
  &.developer-sso-admin-onboarding {
    ${tw`
      -mt-32
      sm:-mt-48
      lg:-mt-72
    `}
  }
`

export const SectionTwoColumns = styled.div`
  ${tw`
    mt-8
    lg:mt-0
    grid
    grid-cols-1
    lg:grid-cols-2
    gap-12
  `}
`
