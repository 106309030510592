import React, { ReactNode } from 'react'
import Container from '../elements/container'
import { Section } from './style'

type SectionWrapper = {
  children: ReactNode
  color?: string
  disabledPadding?: string
  identifier?: string
  type?: string
}

const SectionWrapper = ({ children, color, disabledPadding, identifier, type }: SectionWrapper) => {
  return (
    <Section className={`${color} ${disabledPadding} ${identifier}`}>
      <Container className={type}>{children}</Container>
    </Section>
  )
}

export default SectionWrapper
