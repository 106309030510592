import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Grid = styled.div`
  ${tw`
    grid
    grid-cols-1
  `}
  @media (min-width: 1024px) {
    grid-template-columns: 3fr 1fr;
  }
`

export const ContentAlignment = styled.div`
  &.text-center {
    ${tw`
      text-center
    `}
  }
  &.text-left {
    ${tw`
      text-left
    `}
  }
`

export const Text = styled.div`
  ${tw`
    mx-auto
  `}
`

export const SubHead = styled(Text)`
  p {
    ${tw`
      leading-6
      text-green-500
      font-semibold
      tracking-wide
      lg:max-w-none
    `}
    font-size: 1.25rem;
  }
  p.ui-editor {
    ${tw`
      text-purple-500
    `}
  }
  p.enterprise-connections {
    color: #7872fb;
  }
  p.home {
    color: #3e2381;
  }
`

export const Title = styled(Text)`
  h2 {
    ${tw`
      my-6
      text-3xl
      leading-tight
      md:leading-normal
      font-extrabold
      tracking-tight
      text-purple-900
      sm:text-4xl
      lg:max-w-none
    `}
    @media (min-width: 640px) {
      font-size: 2.75rem;
    }
  }
  h2.on-dark-background {
    ${tw`
      text-white
    `}
  }
`

export const Description = styled(Text)`
  ${tw`
    relative
    z-10
    mb-6
    lg:max-w-full
  `}
  div {
    &.on-dark-background {
      ${tw`
        text-gray-600
      `}
    }
    &.on-light-background {
      ${tw`
        text-gray-700
      `}
    }
  }
  p {
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: 0.015rem;
  }
`

export const CallToAction = styled.div`
  ${tw`
    text-base
    font-bold
    mb-8
  `}
  .authentication {
    ${tw`
      text-teal-500
  `}
  }
`
