/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

const ReadMoreLink = styled.span`
  ${tw`
    flex
    flex-row
    items-center
  `}
  svg {
    ${tw`
      h-4
      w-4
      ml-1
    `}
  }
  white-space: nowrap;
  a {
    ${tw`
      inline-flex
      items-center
      justify-center
      text-base
      leading-6
      font-medium
      transition
      ease-in-out
      duration-150
      text-teal-500
      hover:text-teal-600
    `}
  }
`
export default ReadMoreLink
