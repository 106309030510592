import * as React from 'react'

function SvgCheckIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 11 8" fill="none" {...props}>
      <path
        d="M1.274 3.64l2.5 2.63 5.5-5"
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCheckIcon
