import { Link } from 'gatsby'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import React, { ReactNode } from 'react'
import CallToAction from '../elements/call_to_action'
import ReadMoreLink from '../elements/link'
import { ContentAlignment, Description, Grid, SubHead, Title } from './style'

type SectionH2 = {
  title: string
  description: RichTextBlock[]
  textAlignment: string
  subhead: string | null
  slug: string
  color?: string
  children?: ReactNode
  call_to_action?: boolean
  call_to_action_text?: string
  call_to_action_path?: string
  gridColumns: boolean
  id?: string
  link?: boolean
}

const SectionH2 = ({
  title,
  description,
  textAlignment,
  subhead,
  slug,
  color,
  children,
  call_to_action,
  call_to_action_text,
  call_to_action_path,
  gridColumns,
  id,
  link,
}: SectionH2) => (
  <ContentAlignment className={textAlignment}>
    {gridColumns ? (
      <Grid>
        <div id={id}>
          {subhead && (
            <SubHead>
              <p className={slug}>{subhead}</p>
            </SubHead>
          )}
          <Title>
            <h2 className={color}>{title}</h2>
          </Title>
          <Description>
            <div
              className={
                color === 'on-dark-background' ? `on-dark-background` : `on-light-background`
              }
            >
              {RichText.render(description)}
            </div>
          </Description>
        </div>
      </Grid>
    ) : (
      <div>
        {subhead && (
          <SubHead>
            <p className={slug}>{subhead}</p>
          </SubHead>
        )}
        <Title>
          <h2 className={color}>{title}</h2>
        </Title>
        <Description>
          <div
            className={
              color === 'on-dark-background' ? `on-dark-background` : `on-light-background`
            }
          >
            {RichText.render(description)}
          </div>
        </Description>
      </div>
    )}

    {children || []}
    {call_to_action && (
      <CallToAction>
        <a href={call_to_action_path} className={`color ${slug}`}>
          {call_to_action_text}
        </a>
      </CallToAction>
    )}
    {link && (
      <ReadMoreLink>
        <Link to={call_to_action_path!} className={`color ${slug}`}>
          {call_to_action_text}
        </Link>
      </ReadMoreLink>
    )}
  </ContentAlignment>
)

export default SectionH2
